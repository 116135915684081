import { THEMES } from 'src/app/shared/services/theme.service';
import { versions } from './versions';

export const environment = {
  versions,
  production: false,
  hostUrl: '',
  apiUrl: 'https://stage.benefact4.com/api',
  persistUserSession: true,
  sentryKey: 'https://86acecc08fb74322b197c4225654ff74@sentry.thesunflowerlab.com/49',
  defaultTheme: window.matchMedia('(prefers-color-scheme: dark)') ? THEMES.DARK : THEMES.LIGHT,
  firebase: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  swUpdate: false,
  swUpdateFooter: false,
  horizontalProfileMenu: true,
  encryptionService: {
    enable: false,
    secretKey: 'BfBRoYxsRKGnx3DlPgnsmei7qVHTD62o',
    secretIV: 'cGsgbxxqbcYN6HZG'
  },
  compressAfterFileSize: 2
};
